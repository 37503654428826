/* eslint-disable quotes */
import "lightcase/src/js/lightcase.js";
import "select2/dist/js/select2.min.js";
import 'flickity/dist/flickity.pkgd.min.js';
import Flickity from 'flickity';
import AOS from 'aos';

var shrinkHeader = 5;

export default {
  init() {
    // JavaScript to be fired on all pages

    Flickity.defaults.dragThreshold = 3;
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    AOS.init();

    jQuery(".form-dropdown").select2({
      dropdownParent: $('.select2-wrapper'),
    });

    jQuery("a[data-rel^=lightcase]").lightcase({
      slideshowAutoStart: false,
    });

    $('header .mobile-menu').click(function(){
      $('body').toggleClass('mobile-menu-active');
    });

    gallerySectionSlider()
    cardSectionSlider();
    wrapButtons();
    checkFormLastChild();
  },
};

$(window).on('resize', function () {
  $('.gallery-section .gallery-wrapper, .cards-section .cards-wrp').flickity(
    'resize'
  );
});

$(window).on('load', function () {
  $('.gallery-section .gallery-wrapper, .cards-section .cards-wrp').flickity(
    'resize'
  );

  gallerySectionSlider();
  cardSectionSlider();
});


$(window).scroll(function () {
  var scroll = getCurrentScroll();
  if (scroll >= shrinkHeader) {
    $('body, .header-container').addClass('sticky-header');
  } else {
    $('body, .header-container').removeClass('sticky-header');
  }
});

function getCurrentScroll() {
  return window.pageYOffset || document.documentElement.scrollTop;
}

function gallerySectionSlider() {
  let $gallerySection = $('.gallery-section .gallery-wrapper');

  if ($gallerySection.length > 0) {
    var cellAlignValue = ''

    if (matchMedia('screen and (max-width: 768px)').matches ) {
      cellAlignValue = 'center';
    } else {
      cellAlignValue = 'left';
    }

    $gallerySection.flickity({
      contain: false,
      pageDots: false,
      prevNextButtons: true,
      adaptiveHeight: false,
      wrapAround: false,
      watchCSS: true,
      cellAlign: cellAlignValue,
      initialIndex: 3,
    });
  }
}

function cardSectionSlider() {

  let $cardSectionSlider = $('.cards-section .cards-wrp');

  if ($cardSectionSlider.length > 0) {

    Flickity.prototype._createResizeClass = function() {
      this.element.classList.add('flickity-resize');
    };

    Flickity.createMethods.push('_createResizeClass');

    var resize = Flickity.prototype.resize;
    Flickity.prototype.resize = function() {
      this.element.classList.remove('flickity-resize');
      resize.call( this );
      this.element.classList.add('flickity-resize');
    };

    $cardSectionSlider.flickity({
      pageDots: false,
      prevNextButtons: false,
      wrapAround: true,
      watchCSS: true,
      cellAlign: 'center',
    });
  }
}

//wrap all buttons with a wrapper to handle responsive - This works with "Add Buttons" feature in the wp editor
function wrapButtons() {
  $(".wrap-btns").each(function( ) {
    var wrapper = $(this).find(".btn").unwrap();

    wrapper.wrapAll( "<div class='button-wrapper' />");
  });
}
//wrap all buttons with a wrapper to handle responsive - This works with "Add Buttons" feature in the wp editor

$(window).on("resize scroll", function() {
  animationAnchor();
  animationAnchor2();
});

function animationAnchor() {
  $.fn.isInViewport = function() {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
  };

  $('.two-box-slide').each(function() {
    if ($(this).isInViewport()) {
      $(this).addClass("animation-start");
    }
  });
}

function animationAnchor2() {
  $.fn.isInViewport = function() {
    var elementTop = $(this).offset().top +  $(window).height() / 2;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
  };

  $('.two-col-left, .two-col-right, .two-col-home').each(function() {
    if ($(this).isInViewport()) {
      $(this).addClass("animation-start");
    }
  });
}

function checkFormLastChild() {
  var $prefooterExists = $('.pre-footer').length;

  if (!$prefooterExists) {
    if ($(".form-section").is(":last-child")) {
      $(".form-section").addClass("last-section")
      $(".footer").addClass("form-above")
    }
  }
}