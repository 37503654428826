export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS

    // append tesitimonial slider arrows to intro content

  setTimeout(function(){
    $('.home-testimonial-section .cards-wrp .cards-item-wrp .flickity-button').appendTo('.home-testimonial-section .intro-content');
  }, 200);

    megaNavHomeSeparate();
    testimonialSlider();
  },
};

function megaNavHomeSeparate() {
  $('.home-banner-section .mega-menu-mobile .accordion-wrp').attr('id','mobileMenu');

  $('.home-banner-section .mega-menu-mobile .accordion-wrp .menu-content').each(function() {
    $(this).attr('data-parent','#mobileMenu');
  });

  var i = 1;

  $('.home-banner-section .mega-menu-mobile .accordion-wrp .menu-title').each(function() {
    $(this).attr('data-target','#mobileMenuContent' + i);
    $(this).siblings('.menu-content').attr('id', 'mobileMenuContent' + i)
    i++;
  });
}


function testimonialSlider() {
  let $testimonialSection = $('.home-testimonial-section .cards-item-wrp');

  if ($testimonialSection.length > 0) {
    var cellAlignValue = ''

    if (matchMedia('screen and (max-width: 768px)').matches ) {
      cellAlignValue = 'center';
    } else {
      cellAlignValue = 'left';
    }

    $testimonialSection.flickity({
      contain: true,
      pageDots: true,
      prevNextButtons: true,
      adaptiveHeight: false,
      wrapAround: false,
      cellAlign: cellAlignValue,
    });
  }
}
